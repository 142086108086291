import React from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title?: string;
  description?: string;
};

const Head = ({ title, description }: Props) => {
  const siteMetadata = {
    title: "HIROKI IMAFUKU",
    description:
      "art direction / product design / and graphic design.  Based in Shibuya-ku, Tokyo. Est. 1sr August 2020.",
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={title ? title + " | " + siteMetadata.title : siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: description ? description : siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title ? title : siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description ? description : siteMetadata.description,
        },
        // {
        //   property: `og:image`,
        //   content: `${siteMetadata.siteUrl}`,
        // },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title ? title : siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: description ? description : siteMetadata.description,
        },
      ]}
    ></Helmet>
  );
};
export default Head;
