import React from "react";
import titleSp from "./images/title-sp.png";
import titleSpWebp from "./images/title-sp.webp";
import profileSp from "./images/profile-sp.png";
import profileSpWebp from "./images/profile-sp.webp";
import estSp from "./images/est-sp.png";
import estSpWebp from "./images/est-sp.webp";
import mailSp from "./images/mail-sp.png";
import mailSpWebp from "./images/mail-sp.webp";
import instaSp from "./images/insta-sp.png";
import instaSpWebp from "./images/insta-sp.webp";
import workSp from "./images/work-sp.png";
import workSpWebp from "./images/work-sp.webp";
import titlePc from "./images/title-pc.png";
import titlePcWebp from "./images/title-pc.webp";
import profilePc from "./images/profile-pc.png";
import profilePcWebp from "./images/profile-pc.webp";
import estPc from "./images/est-pc.png";
import estPcWebp from "./images/est-pc.webp";
import mailPc from "./images/mail-pc.png";
import mailPcWebp from "./images/mail-pc.webp";
import instaPc from "./images/insta-pc.png";
import instaPcWebp from "./images/insta-pc.webp";
import workPc from "./images/work-pc.png";
import workPcWebp from "./images/work-pc.webp";
import pattern from "./images/pattern.png";
import Head from "./components/head";
import styled, { keyframes } from "styled-components";
import { GlobalStyle, pth, ptw, theme } from "./components/style";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

const stripe = keyframes`
  0% {
      background-position: 0 0;
    }
  100% {
    background-position: 100% 100%;
  }
`;

const Wrap = styled.div``;
const SPCenter = styled.div`
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 56.221889vh;
  max-width: calc(var(--vh, 1vh) * 56.221889);
  flex-direction: column;
  justify-content: center;
  margin: auto;
  @media ${theme.size.pc} {
    display: none;
  }
`;
const PCCenter = styled(SPCenter)`
  display: none;
  @media ${theme.size.pc} {
    display: flex;
    max-width: 160vh;
    max-width: calc(var(--vh, 1vh) * 160);
  }
`;

const SPWrap = styled.div`
  height: 177.866667vw;
  max-height: 100vh;
  position: relative;
  @media ${theme.size.pc} {
    display: none;
    height: 62.5vw;
  }
`;
const PCWrap = styled.div`
  display: none;
  position: relative;
  @media ${theme.size.pc} {
    display: block;
    height: 62.5vw;
    max-height: 100vh;
  }
`;

const Title = styled.img`
  position: absolute;
  top: ${pth(54)};
  left: ${ptw(297)};
  width: ${ptw(397)};
  @media ${theme.size.pc} {
    top: ${pth(100, "pc")};
    left: ${ptw(240, "pc")};
    width: ${ptw(950, "pc")};
  }
`;

const Profile = styled.img`
  background-color: ${theme.colors.bg};
  position: absolute;
  top: ${pth(54)};
  left: ${ptw(129)};
  width: ${ptw(82)};
  @media ${theme.size.pc} {
    top: ${pth(530, "pc")};
    left: ${ptw(240, "pc")};
    width: ${ptw(665, "pc")};
  }
`;
const Est = styled.img`
  background-color: ${theme.colors.bg};
  position: absolute;
  top: ${pth(54)};
  left: ${ptw(83)};
  width: ${ptw(27)};
  @media ${theme.size.pc} {
    top: ${pth(619, "pc")};
    left: ${ptw(240, "pc")};
    width: ${ptw(282, "pc")};
  }
`;
const Mail = styled.a`
  background: ${theme.colors.bg};
  position: absolute;
  top: ${pth(1158)};
  left: ${ptw(64)};
  width: ${ptw(623)};
  :hover {
    background-image: url(${pattern});
    animation: ${stripe} 20s infinite linear;
  }
  @media ${theme.size.pc} {
    top: ${pth(716, "pc")};
    left: ${ptw(240, "pc")};
    width: ${ptw(356, "pc")};
  }
`;
const Insta = styled.a`
  background: ${theme.colors.bg};
  position: absolute;
  top: ${pth(1232)};
  left: ${ptw(64)};
  width: ${ptw(623)};
  :hover {
    background-image: url(${pattern});
    animation: ${stripe} 20s infinite linear;
  }
  @media ${theme.size.pc} {
    top: ${pth(765, "pc")};
    left: ${ptw(240, "pc")};
    width: ${ptw(356, "pc")};
  }
`;
const Work = styled.img`
  position: absolute;
  top: ${pth(959)};
  left: ${ptw(92)};
  width: ${ptw(140)};
  @media ${theme.size.pc} {
    top: ${pth(656, "pc")};
    left: ${ptw(1011, "pc")};
    width: ${ptw(158, "pc")};
  }
`;

const Bg = styled.div`
  background-color: ${theme.colors.bg};
  position: absolute;
  z-index: -1;
`;
const TitleBg = styled(Bg)`
  top: ${pth(54)};
  left: ${ptw(590)};
  height: ${pth(455)};
  width: ${ptw(104)};
  @media ${theme.size.pc} {
    top: ${pth(100, "pc")};
    left: ${ptw(240, "pc")};
    height: ${pth(85, "pc")};
    width: ${ptw(422, "pc")};
  }
`;
const WorkBg = styled(Bg)`
  border-radius: 100%;
  top: ${pth(888)};
  left: ${ptw(62)};
  height: ${pth(200)};
  width: ${ptw(200)};
  @media ${theme.size.pc} {
    top: ${pth(580, "pc")};
    left: ${ptw(980, "pc")};
    height: ${pth(220, "pc")};
    width: ${ptw(220, "pc")};
  }
`;

const App = () => {
  const isSP = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const isPC = useMediaQuery({
    query: "(min-width: 768px)",
  });
  // const variants = {
  //   active: {
  //     background: `url(${pattern})`,
  //     backgroundPosition: "100% 100%",
  //     transition: {
  //       duration: 10,
  //       repeat: Infinity,
  //     },
  //   },
  //   inactive: {
  //     background: theme.colors.bg,
  //   },
  // };
  const setHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  setHeight();
  window.addEventListener("resize", setHeight);
  return (
    <Wrap className="App">
      <GlobalStyle />
      <Head />
      {isSP && (
        <SPCenter>
          <SPWrap>
            <picture>
              <source type="image/webp" srcSet={titleSpWebp} />
              <Title src={titleSp} alt="title" />
            </picture>
            <TitleBg />
            <picture>
              <source type="image/webp" srcSet={profileSpWebp} />
              <Profile src={profileSp} alt="profile" />
            </picture>
            <picture>
              <source type="image/webp" srcSet={estSpWebp} />
              <Est src={estSp} alt="est" />
            </picture>
            <Mail
              href="mailto:h@imfk.tokyo"
              // initial="inactive"
              // whileHover="active"
              // whileTap="active"
              // variants={variants}
              // layout
            >
              <picture>
                <source type="image/webp" srcSet={mailSpWebp} />
                <motion.img
                  src={mailSp}
                  alt="mail"
                  whileHover={{ opacity: 0.8 }}
                  whileTap={{ opacity: 0.8 }}
                />
              </picture>
            </Mail>
            <Insta
              href="https://www.instagram.com/imfkhrk/"
              target="_blank"
              rel="noopener"
              // initial="inactive"
              // whileHover="active"
              // whileTap="active"
              // variants={variants}
              // layout
            >
              <picture>
                <source type="image/webp" srcSet={instaSpWebp} />
                <motion.img
                  src={instaSp}
                  alt="insta"
                  whileHover={{ opacity: 0.8 }}
                  whileTap={{ opacity: 0.8 }}
                />
              </picture>
            </Insta>
            <picture>
              <source type="image/webp" srcSet={workSpWebp} />
              <Work src={workSp} alt="work" />
            </picture>
            <WorkBg />
          </SPWrap>
        </SPCenter>
      )}
      {isPC && (
        <PCCenter>
          <PCWrap>
            <picture>
              <source type="image/webp" srcSet={titlePcWebp} />
              <Title src={titlePc} alt="title" />
            </picture>
            <TitleBg />
            <picture>
              <source type="image/webp" srcSet={profilePcWebp} />
              <Profile src={profilePc} alt="profile" />
            </picture>
            <picture>
              <source type="image/webp" srcSet={estPcWebp} />
              <Est src={estPc} alt="est" />
            </picture>
            <Mail
              href="mailto:h@imfk.tokyo"
              // initial="inactive"
              // whileHover="active"
              // whileTap="active"
              // variants={variants}
              // layout
            >
              <picture>
                <source type="image/webp" srcSet={mailPcWebp} />
                <motion.img
                  src={mailPc}
                  alt="mail"
                  whileHover={{ opacity: 0.8 }}
                  whileTap={{ opacity: 0.8 }}
                />
              </picture>
            </Mail>
            <Insta
              href="https://www.instagram.com/imfkhrk/"
              target="_blank"
              rel="noopener"
              // initial="inactive"
              // whileHover="active"
              // whileTap="active"
              // variants={variants}
            >
              <picture>
                <source type="image/webp" srcSet={instaPcWebp} />
                <motion.img
                  src={instaPc}
                  alt="insta"
                  whileHover={{ opacity: 0.8 }}
                  whileTap={{ opacity: 0.8 }}
                />
              </picture>
            </Insta>
            <picture>
              <source type="image/webp" srcSet={workPcWebp} />
              <Work src={workPc} alt="work" />
            </picture>
            <WorkBg />
          </PCWrap>
        </PCCenter>
      )}
    </Wrap>
  );
};

export default App;
