import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

// px to vw
const designSize = {
  sp: 750,
  tablet: 768,
  pc: 1440,
};
export const ptw = (num: number, device?: string) => {
  switch (device) {
    case "pc":
      return `${(num / designSize.pc) * 100}%`;
    default:
      return `${(num / designSize.sp) * 100}%`;
  }
};
export const pth = (num: number, device?: string) => {
  switch (device) {
    case "pc":
      return `${(num / 900) * 100}%`;
    default:
      return `${(num / 1334) * 100}%`;
  }
};

export const theme = {
  colors: {
    bg: "#00ffff",
  },
  size: {
    sp: "screen and (max-width: 440px)",
    tablet: "screen and (min-width: 441px) and (max-width: 767px)",
    pc: "screen and (min-width: 768px)",
  },
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  html{
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
  a{
    cursor: pointer;
  }
  img{
    display: block;
    height: auto;
    width: 100%;
  }
`;
